import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React,{ChangeEvent} from 'react';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  filterTableData,
  getTableKey,
  getGroupbyTableKey,
} from "../../../components/src/Utils/utils";
import { TableData } from "../../../components/src/Utils/types";
export type token = string | null;
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import * as Yup from "yup";
import { FormikProps } from "formik";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface File {
  name: string;
  type: string;
}

type FormDataType = {
  canbesold: boolean;
  canbepurchased: boolean;
  product_type: string;
  invoice_policy: string;
  unit_of_measure: string;
  purchased_uom: string;
  product_code: string;
  sales_price: number;
  customer_tax: {
    id: number;
    title: string;
    value: string;
  }[];
  product_category: string;
  internal_notes: string;
  cost: number;
  optional_products: {
    id: number;
    title: string;
    value: string;
  }[]; 
  accessory_products: {
    id: number;
    title: string;
    value: string;
  }[]; 
  alternative_products: {
    id: number;
    title: string;
    value: string;
  }[]; 
  available_in_POS: boolean;
  to_weigh_with_scale: boolean;
  sales_description: string;
  account_id: number;
  extra_product_media: File[]; 
  responsible: string;
  barcode: string;
  weight: string;
  trackingValue: string;
  volume: string;
  manuf_lead_time: string;
  customer_lead_time: string;
  hs_code: string;
  description_for_receipts: string;
  description_for_delivery_orders: string;
  description_for_internal_transfers: string;
  control_policy: string;
  description_for_purchase: string;
  vendor_tax: {
    id: number;
    title: string;
    value: string;
  }[];
  purchase_description: string;
}

export const tableHeading = [
  "Name",
  "Responsible",
  "Product Code",
  "Sales Price",
  "Cost",
  "Category",
  "Type",
  "QTY on Hand",
  "Forecasted QTY",
  "UMO",
];

export const tableBodyContent = {
  name: "LED TV",
  responsible: "Mark Demo",
  productCode: "456875DFGT",
  salesPrice: "50.00 OMR",
  cost: "30.00 OMR",
  category: "All/Delivery",
  type: "Storable Product",
  qTYOnHand: "16.00",
  forecastedQTY: "132.00",
  UMO: "Units",
};

type tableBodyContentType = {
  id: string;
  name: string;
  productImage: string;
  purchaseDescription: string;
  responsible: string;
  productCode: string;
  salesPrice: string;
  cost: string;
  category: string;
  type: string;
  qTYOnHand: number;
  forecastedQTY: number;
  UMO: string;
  checked: boolean;
  delivery: number;
};

type groupbyBodyContentType = {
  key: string;
  total_sales_price: number;
  data: tableBodyContentType[];
};

export const tableBody = Array(18).fill(tableBodyContent);

interface UomData {
  id: string;
  type: string;
  attributes: {
    category: string | null;
    unspsc_category: string;
    archive: boolean;
    unit_of_measures: {
      id: number;
      ratio: number;
      active: boolean | null;
      rounding_precision: number;
      uom: string;
      uom_type: string;
      uom_category_id: number;
      archive: boolean | null;
    }[];
  };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: Record<string, any>;
  formikProps?: FormikProps<any>;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isViewTypeChanged: boolean;
  anchorEl: HTMLElement | null;
  anchorElement: HTMLElement | null;
  token: token;
  filteredData: TableData[];
  selectedFilter: string;
  tableData: TableData[];
  responseData: tableBodyContentType[] | groupbyBodyContentType[];
  isChecked: boolean;
  isAllChecked: boolean;
  currentPage: number;
  rowsPerPage: number;
  isGeneraltabEditing:boolean;
  isSalesEditing:boolean;
  isPurchaseEditing:boolean;
  isInventoryEditing:boolean;
  isAccountingEditing:boolean
  productId:string;
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  totalCounts: number;
  dataLength: number;
  query: string;
  anchorElFilter: HTMLElement | null;
  anchorElGroupBy: HTMLElement | null;

  params: { [name: string]: boolean | string };

  selectedItems: {
    [name: string]: boolean;
  };

  isGroupby: boolean;
  isLoading: boolean;
  isLoadMore:boolean;
  queryItems: { id: string, tag: string, key: string, type: string }[];
  completeProductData: tableBodyContentType[] | groupbyBodyContentType[];
  initialLoadComplete: boolean;
  remainingItems: number;

  uomCategoriesData: Array<Object>;
  productCategoriesData: Array<Object>;
  salesAttributesData: Array<Object>;
  selectedTab: number;
  loading:boolean;
  product_name_error: string;
  product_name_touched: boolean;
  product_name: string;
  canbesold: boolean;
  canbepurchased: boolean;
  isProductNameNotValid: boolean;
  productImage: any;
  imageUploadValid:boolean;
  productImageError: boolean;
  generalinfo: {
    product_type: string;
    invoice_policy: string;
    unit_of_measure: string;
    purchased_uom: string;
    product_code: string;
    sales_price: number;
    customer_tax: string | string[];
    product_category: string;
    internal_notes: string;
    cost: number;
  },
  sales: {
    id:number,
    optional_products: string[],
    accessory_products: string[],
    alternative_products: string[],
    available_in_POS: boolean,
    to_weigh_with_scale: boolean,
    sales_description: string,
    account_id: number,
    extra_product_media: File[],
  },

  filteredSelectedData:[],
  groupbySelectedData:[],
  inventory:{
    tracking: boolean;
    delivery_orders_description: string;
    internal_transfer_description: string;
    receipts_description: string;
    responsible: string,
    barcode: number | string,
    weight: string,
    trackingValue: string,
    volume: string,
    manuf_lead_time: string,  
    customer_lead_time: string,
    hs_code: string | number,
    description_for_receipts: string,
    description_for_delivery_orders: string,
    description_for_internal_transfers: string,
  },
  accountingAttributesData: any,
  accounting: {  
    income_account: string,
    expense_account: string,
    price_difference_account: string,
    commodity_code: string,
    country_of_origin: string,
    automatic_email_at_invoice: string,
    product_id: number,
    accounting_account_id: number
  }
  purchaseAttributesData: Array<Object>,
  vendorsListTableData: Array<{
    vendor_id: number, 
    vendor_name: string,
    subcontracted: string,
    product_variant: string,
    currency: string,
    qty: string,
    uom: string,
    price: string,
    delivery_time_lead: string,
  }>,
  vendorNameList:{vendor_name:string}[],
  deleteDialog: boolean,
  deleteRowId: number,
  deleteVendorId: string,
  purchase: {
    type: string,
    vendor_tax: string | string[],
    purchase_description: string,
    product_id: number,
    account_id: number,
    control_policy: string,
    description_for_purchase: string,
  }
  vendorTaxOption:any;
  optionalProductOption:any;
  accessoryProductOption:any;
  alernativeProductOption:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


export const filterOptions = [
  { name: "Active Status", keys: "active" },
  { name: "Archived", keys: "archive" },
  { name: "Available in POS", keys: "available_in_POS" },
  { name: "Can be Sold", keys: "can_be_sold" },
  { name: "Can be purchased", keys: "can_be_purchased" },
];

export const groupByOptions = [
  { name: "Product Category", keys: "product_category" },
  { name: "Product Type", keys: "product_type" },
  { name: "POS Product category Type", keys: "pos_product_type" },
  { name: "Active Status", keys: "can_be_purchased" },
];

export const SettingOptions = [
  { name: "Export" },
  { name: "Archive" },
  { name: "Unarchive" },
  { name: "Delete" },
];

export default class ItemavailabilityController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getItemsAvailabilityAPI: string = "";
  getItemsAvailabilityDataAPI: string = "";
  productGeneralInfoAPICallId: string = "";
  getUomCategoriesAPI: string = "";
  getProductCategoriesAPI: string = "";
  getSalesAttributesAPI: string = "";
  getAccountingAttributesData: string = "";
  getPurchaseAttributesAPI: string = "";
  deletePurchaseAPI: string = "";
  getVendorsListAPI: string = "";
  getSingleProductAPI:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isViewTypeChanged: true,
      tableData: tableBody,
      anchorEl: null,
      anchorElement: null,
      token: "",
      filteredData: tableBody,
      selectedFilter: "",
      responseData: [],
      isChecked: false,
      isAllChecked: false,
      isGeneraltabEditing:false,
      isSalesEditing:false,
      isPurchaseEditing:false,
      isInventoryEditing:false,
      isAccountingEditing:false,
      productId:'',
      currentPage: 1,
      rowsPerPage: 9,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      totalCounts: 0,
      dataLength: 0,
      query: "",
      anchorElFilter: null,
      anchorElGroupBy: null,
      selectedItems: {},

      params: {},
      isGroupby: false,
      isLoading: false,
      isLoadMore:false,
      queryItems: [],
      completeProductData: [],
      initialLoadComplete:false,
      remainingItems:0,
      loading:true,
      uomCategoriesData: [],
      productCategoriesData: [],
      salesAttributesData: [],
      selectedTab: 0,
      product_name_error: "",
      product_name_touched: false,
      product_name: "",
      canbesold: false,
      canbepurchased: false,
      isProductNameNotValid: false,
      productImage: null,
      productImageError:false,
      imageUploadValid:false,
      generalinfo: {
        product_type: "",
        invoice_policy: "",
        unit_of_measure: "",
        purchased_uom: "",
        product_code: "",
        sales_price: 0,
        customer_tax: [],
        product_category: "",
        internal_notes: "",
        cost: 0,
      },
      sales: {
        id:0,
        optional_products: [],
        accessory_products: [],
        alternative_products: [],
        available_in_POS: false,
        to_weigh_with_scale: false,
        sales_description: "",
        account_id: 317,
        extra_product_media: [],
      },
      filteredSelectedData: [],
      groupbySelectedData: [],
      inventory: {
        tracking:false,
        delivery_orders_description: '',
        internal_transfer_description: '',
        receipts_description: '',
        responsible: "created user",
        barcode: "",
        weight: "",
        trackingValue: "By Unique Serial Number",
        volume: "",
        manuf_lead_time: "",
        customer_lead_time: "",
        hs_code: "",
        description_for_receipts: "",
        description_for_delivery_orders: "",
        description_for_internal_transfers: "",
      },
      accountingAttributesData: {},
      accounting: {
        income_account: "",
        expense_account: "",
        price_difference_account: "",
        commodity_code: "",
        country_of_origin: "",
        automatic_email_at_invoice: "",
        product_id: 220,
        accounting_account_id: 1
      },
      purchaseAttributesData: [],
      vendorsListTableData : [{
        vendor_id: 0,
        vendor_name: "",
        subcontracted: "---",
        product_variant: "Height 700 cm",
        currency: "OMR",
        qty: "01.00",
        uom: "Units",
        price: "500.00 OMR",
        delivery_time_lead: "1",
      }],
      vendorNameList:[
        {vendor_name:"Vendor 1"},
        {vendor_name:"Vendor 2"},
        {vendor_name:"Vendor 3"},
      ],
      deleteDialog: false,
      deleteRowId: 0,
      deleteVendorId: "",
      purchase: {
        type: "",
        vendor_tax: [],
        purchase_description: "",
        product_id: 0,
        account_id: 0,
        control_policy: "On received quantities",
        description_for_purchase: "",
      },
      vendorTaxOption:[],
      optionalProductOption:[],
      accessoryProductOption:[],
      alernativeProductOption:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
        if(apiRequestCallId === this.getSingleProductAPI && responseJson.data){
          const currentProductData = responseJson.data.data.attributes
          this.setState({
            product_name:currentProductData.product_name,
            canbesold:currentProductData.can_be_sold,
            canbepurchased:currentProductData.can_be_purchased,
            generalinfo:currentProductData.general_info,
            sales:currentProductData.sale,
            inventory:currentProductData.inventory,
            accounting:currentProductData.accounting,
            purchase:currentProductData.purchases,
            productImage:currentProductData.product_image.url
            
          },()=>{this.setState({loading:false})})
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({
        enableField: !this.state.enableField,
      });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({
      enableField: !this.state.enableField,
    });
  };

  // Customizable Area Start

  productImageRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  extraMediaRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();


  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }
    else {
      this.fetchDataLength();
      this.fetchFilterGroupData();
      this.fetchUomCategories();
      this.fetchProductCategories();
      this.fetchSalesAttributes();
      this.fetchPurchaseAttributes();
      this.fetchVendorsList();
    }
    const savedState =  await getStorageData("editingState")
    if (savedState) {
      const editingState = JSON.parse(savedState);
      
      this.getSingleProductData(editingState.productId);
      this.setState(editingState);
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = this.state.token;
    if (prevState.query !== this.state.query ||
      (prevState.currentPage !== this.state.currentPage ||
        prevState.rowsPerPage !== this.state.rowsPerPage)
    ) {
      let searchString = ""
      if (this.state.query.length >= 3) {
        searchString = `&search_text=${this.state.query}`
      }
      const { currentPage, rowsPerPage } = this.state;
      const params = `page=${currentPage}&per_page=${rowsPerPage}${searchString}`
      this.getItemsAvailabilityAPI = this.apiCall(
        token,
        `bx_block_productquickview/products?${params}`,
        "GET"
      );
    }
    else if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.queryItems.length !== this.state.queryItems.length) {
      this.fetchFilterGroupData()
    }

  }

  fetchFilterGroupData = async () => {
    this.setState({
      isLoading: true,
    })
    const token = this.state.token;
    let params = {} as any
    this.state.queryItems.forEach((item: any) => {
      if (item.hasOwnProperty("key")) {
        params[item.key] = true
      }
    })

    const strng = Object.keys(params)
      .map(function (keys) {
        return keys + "=" + params[keys];
      })
      .join("&");
    const { currentPage, rowsPerPage } = this.state;
    
    
    this.getItemsAvailabilityAPI = this.apiCall(
      token,
      this.state.isGroupby ? `bx_block_productquickview/products?${strng}` :`bx_block_productquickview/products?page=${currentPage}&per_page=${rowsPerPage}&${strng}`,
      "GET"
    );
  };

  fetchDataLength = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.setState({
      isLoading: true,
    })
    this.getItemsAvailabilityDataAPI = this.apiCall(
      token,
      `bx_block_productquickview/products?page=1&per_page=1000`,
      "GET"
    );
  };

  fetchUomCategories = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getUomCategoriesAPI = this.apiCall(
      token,
      `/bx_block_productquickview/uom_categories?page=1&per_page=9`,
      "GET"
    );
  };

  fetchProductCategories = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getProductCategoriesAPI = this.apiCall(
      token,
      `/bx_block_productquickview/product_categories`,
      "GET"
    );
  };

  fetchSalesAttributes = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getSalesAttributesAPI = this.apiCall(
      token,
      `bx_block_salesreporting/sales`,
      "GET"
    );
  };

  fetchPurchaseAttributes = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getPurchaseAttributesAPI = this.apiCall(
      token,
      `bx_block_productquickview/purchases`,
      "GET"
    );
  };

  fetchVendorsList = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getVendorsListAPI = this.apiCall(
      token,
      `/account_block/accounts/get_customers_list?usertype=vendor`,
      "GET"
    );
  };

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {
      data: object[];
      meta: { total_pages: number; total_counts: number };
      message:string;
    }
  ) => {
    if (apiRequestCallId === this.getItemsAvailabilityAPI) {
      this.handleGetItemResponse(
        responseJson.data,
        responseJson.meta.total_pages,
        responseJson.meta.total_counts
      );
    }
    if(apiRequestCallId === this.productGeneralInfoAPICallId){
      if(responseJson.message){
        toast.success(responseJson.message)
        this.props.navigation.navigate("Itemavailability");
      }
    }
    if (apiRequestCallId === this.getItemsAvailabilityDataAPI) {
      this.handleGetDataLegthResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getUomCategoriesAPI) {
      this.handleGetUomCategoriesResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getProductCategoriesAPI) {
      this.handleGetProductCategoryResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getSalesAttributesAPI) {
      this.handleSalesAttributesResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getPurchaseAttributesAPI) {
      this.handlePurchaseAttributesResponse(responseJson.data);
    }
  };

  extractUomValues(data: UomData[]): { id: string; title: string; value: string }[] {
    const optionsList: { id: string; title: string; value: string }[] = [];
    if (Array.isArray(data)) {
      for (const item of data) {
        const uomArray = item.attributes.unit_of_measures;
        if(Array.isArray(uomArray)){
          for (const uomItem of uomArray) {
            if (uomItem.uom!==" "){
              optionsList.push({
                id: uomItem.id.toString(),
                title: uomItem.uom,
                value: uomItem.uom,
              });
            }
          }}
      }
    }

    return optionsList;
  }

  extractProductCategoryValues(data: any[]): { id: string; title: string; value: string; }[] {
    const categories: { id: string; title: string; value: string }[] = [];
    if (Array.isArray(data)) {
      for (const item of data) {
        const category = {
          product_id: item.attributes.product_id.toString(),
          product_category: item.attributes.product_category,
        };
        if (category.product_category){
          categories.push({
            id: category.product_id,
            title: category.product_category,
            value: category.product_category,
          });
        }
      }
    }

    return categories;
  }

  extractOptionalProducts() {
    const optionalProductsArray: { id: string; title: string; value: string }[] = [];
    const data= this.state.salesAttributesData
    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        const attributes = item.attributes;
        if (attributes.optional_products && attributes.optional_products.length > 0) {
          optionalProductsArray.push({
            id: attributes.product_id,
            title: attributes.optional_products[0],
            value: attributes.optional_products[0],
          });
        }
      });
    }
    this.setState({
      optionalProductOption:optionalProductsArray
    })
  }

  extractAccessoryProducts() {
    const accessoryProductsArray: { id: string; title: string; value: string }[] = [];
    const data=this.state.salesAttributesData
    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        const attributes = item.attributes;
        if (attributes.accessory_products && attributes.accessory_products.length > 0) {
          accessoryProductsArray.push({
            id: attributes.product_id,
            title: attributes.accessory_products[0],
            value: attributes.accessory_products[0],
          });
        }
      });
    }
    this.setState({
      accessoryProductOption:accessoryProductsArray
    })
  }

  extractAlternativeProducts() {
    const alternativeProductsArray: { id: string; title: string; value: string }[] = [];
    const data=this.state.salesAttributesData
    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        const attributes = item.attributes;
        if (attributes.alternative_products && attributes.alternative_products.length > 0) {
          alternativeProductsArray.push({
            id: attributes.product_id,
            title: attributes.alternative_products[0],
            value: attributes.alternative_products[0],
          });
        }
      });
    }
    this.setState({
      alernativeProductOption:alternativeProductsArray
    })
  }



  toggleMenu = (anchorEl: HTMLElement | null) => {
    this.setState({ anchorEl });
  };

  toggleColumn = (anchorElement: HTMLElement | null) => {
    this.setState({ anchorElement });
  };

  handleFilterChange = (selectedFilters: Array<string>) => {
    const { tableData } = this.state;
    const filteredResult = filterTableData(tableData, selectedFilters);
    this.setState({
      filteredData: filteredResult,
      isAllChecked: false,
    });
  };


  handleGetItemResponse = (
    data: object[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = this.state.isGroupby
      ? getGroupbyTableKey(data)
      : getTableKey(data);
      this.setState({ responseData: tableInfo, totalPage, totalCounts });
      
      this.setState({
        isLoading: false,
        isLoadMore: false,
      })
  };

  handleGetItemSearchResponse = (
    data: object[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = getTableKey(data);
    this.setState({ responseData: tableInfo, totalPage, totalCounts });
    
    this.setState({
      isLoading: false,
      isLoadMore:false
    })
  };

  handleGetDataLegthResponse = (data: any) => {
    const tableInfoLength = data?.length;
    this.setState({
      dataLength: tableInfoLength,
      completeProductData: data,
    });
  };

  handleGetUomCategoriesResponse = (data: any) => {
    this.setState({
      uomCategoriesData: data
    });
  };

  handleGetProductCategoryResponse = (data: any) => {
    this.setState({
      productCategoriesData: data
    });
  };

  handleSalesAttributesResponse = (data: any) => {
    this.setState({
      salesAttributesData: data
    });
    this.extractOptionalProducts();
    this.extractAccessoryProducts();
    this.extractAlternativeProducts();
  };

  handlePurchaseAttributesResponse = (data: any) => {
    this.setState({
      purchaseAttributesData: data
    });
    this.extractVendorTax();
  }

  
  extractVendorTax() {
    const vendorTaxArray: { id: string; title:string, value: string }[] = [];
    const data= this.state.purchaseAttributesData;

    if (Array.isArray(data)) {
      data.forEach((item: any) => {

        const attributes = item.attributes;
        const pid= item.id;
        if (attributes.vendor_tax && attributes.vendor_tax.length > 0) {
          vendorTaxArray.push({
            id: pid,
            title: attributes.vendor_tax,
            value: attributes.vendor_tax,
          });
        }
      });
    }

    this.setState({
      vendorTaxOption : vendorTaxArray
    })
  }

  handleLoadMore = () => {
    const { totalCounts, rowsPerPage, remainingItems, initialLoadComplete } = this.state;
    const leftOver = totalCounts - rowsPerPage;
    const newRowsPerPage = rowsPerPage + 18;
    this.setState({ remainingItems: leftOver, isLoadMore: true,rowsPerPage:newRowsPerPage })

    if (!initialLoadComplete || (leftOver > 0 && remainingItems > 0)) {
      this.setState({
        remainingItems: leftOver,
        isLoadMore: true,
        initialLoadComplete: true,
        rowsPerPage: newRowsPerPage,
      });
    }
  }


  handleCheckBoxSelectGroupby = (item: tableBodyContentType, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: groupbyBodyContentType[]) => {
    if (item.checked) {
      updatedSelectedItem[item.id] = true;
    } else if (updatedSelectedItem.hasOwnProperty(item.id)) {
      delete updatedSelectedItem[item.id];
    }

    checkBoxData.forEach((element : groupbyBodyContentType) => {
      element.data[index] = item;
    })

    return checkBoxData.every((element : groupbyBodyContentType) => {
      return element.data.every((item: tableBodyContentType) => item.checked) === true
    })
  }

  handleCheckBoxSelectFilter = (item: tableBodyContentType, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: any) => {
    if (item.checked) {
      updatedSelectedItem[item.id] = true;
    } else if (updatedSelectedItem.hasOwnProperty(item.id)) {
      delete updatedSelectedItem[item.id];
    }

    checkBoxData[index] = item;
    return checkBoxData.every((item: tableBodyContentType) => item.checked);
  }


  handleCheckBoxSelect = (item: tableBodyContentType, index: number) => {
    const checkBoxData = [...this.state.responseData] as any
    const updatedSelectedItem = { ...this.state.selectedItems };

    item.checked = !item.checked;

    let isAllChecked = false
    if (this.state.isGroupby) {
      isAllChecked = this.handleCheckBoxSelectGroupby(item, index, updatedSelectedItem, checkBoxData);
    } else {
      isAllChecked = this.handleCheckBoxSelectFilter(item, index, updatedSelectedItem, checkBoxData);
    }

    this.setState({
      responseData: checkBoxData,
      selectedItems: updatedSelectedItem,
      isAllChecked: isAllChecked,
    });
    this.setState({
      isLoading: false,
      isLoadMore:false,
    })
  };

  handleAllCheckFilter = (updatedSelectedItem: { [name: string]: boolean }) => {
    const filterData = this.state.responseData as tableBodyContentType[]
    const checkBoxData = filterData.map((item:tableBodyContentType) => {

      item.checked = !this.state.isAllChecked;

      if (item.checked) {
        updatedSelectedItem[item.id] = item.checked;
      } else {
        if (updatedSelectedItem.hasOwnProperty(item.id)) {
          delete updatedSelectedItem[item.id];
        }
      }

      return item;
    });
    this.setState((prev) => {
      return {
        responseData: checkBoxData,
        isAllChecked: !prev.isAllChecked,
        selectedItems: updatedSelectedItem,
      };
    });
    this.setState({
      isLoading: false,
      isLoadMore:false,
    })
  }

  handleAllCheckGroupby = (updatedSelectedItem: { [name: string]: boolean }) => {
    const groupbyData = this.state.responseData as groupbyBodyContentType[]
    const checkBoxData = groupbyData.map((element: groupbyBodyContentType) => {
      let updatedElement = element
      updatedElement.data = element.data.map((item: tableBodyContentType) => {
        item.checked = !this.state.isAllChecked;
        if (item.checked) {
          updatedSelectedItem[item.id] = item.checked;
        } else {
          if (updatedSelectedItem.hasOwnProperty(item.id)) {
            delete updatedSelectedItem[item.id];
          }
        }
        return item;
      })
      return updatedElement
    }
    );
    this.setState((prev) => {
      return {
        responseData: checkBoxData,
        isAllChecked: !prev.isAllChecked,
        selectedItems: updatedSelectedItem,
      };
    });
    this.setState({
      isLoading: false,
      isLoadMore:false
    })
  }

  handleAllCheck = () => {
    let updatedSelectedItem = Object.assign({}, this.state.selectedItems);
    if (!this.state.isGroupby) {
      this.handleAllCheckFilter(updatedSelectedItem)
    } else {
      this.handleAllCheckGroupby(updatedSelectedItem)
    }
  };

  handleChangePage = (pageNo: number) => {
    this.setState({
      currentPage: pageNo + 1,
      isLoading:true,
    });
    if (this.state.currentPage > pageNo) {
      this.setState((prev) => ({
        row: {
          from: prev.row.from - this.state.rowsPerPage,
          end: prev.row.end - this.state.rowsPerPage,
        },
      }));
    } else {
      this.setState((prev) => ({
        row: {
          from: prev.row.from + this.state.rowsPerPage,
          end: prev.row.end + this.state.rowsPerPage,
        },
      }));
    }
    this.setState({
      isAllChecked: false,
    })
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value as string),
      currentPage: 1,
    });
  };
  handleClickFilter = (event: any) => {
    this.setState({
      anchorElFilter: this.state.anchorElFilter ? null : event.currentTarget,
      query:"",
    });
  };

  handleClickGroupBy = (event: any) => {
    this.setState({
      anchorElGroupBy: this.state.anchorElGroupBy ? null : event.currentTarget,
    });
  };

  handleClickSetting = (event: any) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    });
  };

  handleGroupBySelect = (item: { name: string; keys: string }) => {
    if (!this.state.isViewTypeChanged) {
      this.setState({
        isViewTypeChanged: true,
      })
    }
    this.setState((prevState) => {
      const updatedQueryItems = [...prevState.queryItems];

      const existingIndex = updatedQueryItems.findIndex(
        (queryItem) => queryItem.type === "groupby"
      );

      if (existingIndex !== -1) {
        updatedQueryItems[existingIndex] = {
          id: item.keys,
          tag: item.name,
          key: item.keys,
          type: "groupby",
        };
      } else {
        updatedQueryItems.push({
          id: item.keys,
          tag: item.name,
          key: item.keys,
          type: "groupby",
        });
      }

      return { queryItems: updatedQueryItems };
    });
    this.setState({
      isGroupby: true,
      anchorElGroupBy: null,
      isAllChecked: false,
    })
  };

  handleRemoveTag = (item: { id: string; tag: string; key: string; type: string; }) => {
    const updatedQueryItems = this.state.queryItems.filter((queryItem) => queryItem.key !== item.key);
    this.setState({ queryItems: updatedQueryItems, query:"" });
    this.state.queryItems.forEach((item) => {
      if (item.type !== "groupby") {
        this.setState({ isGroupby: false });
      }
    })
    if (!updatedQueryItems.length) {
      this.setState({ isGroupby: false });
    }
    this.setState({
      currentPage: 1,
    })
  }


  onChangeSearchField = (event: any) => {
    const { value } = event.target;
    this.setState({
      query: value,
      currentPage: 1,
    });
    return {};
  }

  handleAddRow = () => {
    const newRow = {
      vendor_id: 0,
      vendor_name: "",
      subcontracted: "---",
      product_variant: "Height 700 cm",
      currency: "OMR",
      qty: "01.00",
      uom: "Units",
      price: "500.00 OMR",
      delivery_time_lead: "1",
    };

    this.setState({
      vendorsListTableData: this.state.vendorsListTableData.concat(newRow),
    })
  };

  handleDeleteRow = (rowIndex: number, vendorId: string) => {
    this.setState({
      deleteDialog: true,
      deleteRowId: rowIndex,
      deleteVendorId: vendorId
    })
  };

  handleDialogCancel = () => {
    this.setState({
      deleteDialog: false,
    })
  }

  onDeletePurchase = () => {
    const { deleteRowId, deleteVendorId, token } = this.state;
    if (deleteVendorId == "") {
      this.setState((prevState) => ({
        vendorsListTableData: prevState.vendorsListTableData.filter((arrData, i) => i !== deleteRowId),
      }));
    }
    else{
      this.deletePurchaseAPI = this.apiCall(
        token,
        `/bx_block_productquickview/purchases/${deleteVendorId}`,
        "DELETE",
      );
    }
    this.setState({
      deleteDialog: false,
    })
  }

  handleFilterSelect = (item: { name: string; keys: string }) => {
    const existingItemIndex = this.state.queryItems.findIndex(
      (queryItem) => queryItem.key === item.keys
    );
    if (existingItemIndex !== -1) {
      this.setState({ anchorElFilter: null });
    } else {
      this.setState({
        queryItems: [
          ...this.state.queryItems,
          { id: item.keys, tag: item.name, key: item.keys, type: "filter" },
        ],
        anchorElFilter: null,
        currentPage:1,
      });
    }
  };

  handleClickAwayFilter = () => {
    this.setState({ anchorElFilter: null });
  };

  handleClickAwayGroup = () => {
    this.setState({ anchorElGroupBy: null });
  };

  handleClickAwaySettings = () => {
    this.setState({ anchorEl: null });
  };

  validateInput = (input: string) => {
    if (!input?.length) {
      this.setState({ isProductNameNotValid: true, product_name_error: 'Product Name is required' });
      return 'Product Name is required'
    }
    else if (input.length < 3) {
      this.setState({ isProductNameNotValid: true, product_name_error: 'Product Name must be at least 3 characters' });
      return 'Product Name must be at least 3 characters';
    }
    else if (input.length > 64) {
      this.setState({ isProductNameNotValid: true, product_name_error: 'Product Name must not exceed 64 characters' });
      return 'Product Name must not exceed 64 characters';
    }
    this.setState({ isProductNameNotValid: false });
    return '';
  };


  handleProductNameChange = (event: any) => {
    const value = event.target.value;
    this.setState({
      product_name: value,
    })
    const validationError = this.validateInput(value);
    this.setState({
      product_name_error: validationError,
    })
  };  
   
  handleProductNameBlur = () => {
    this.setState({
      product_name_touched: true,
    });

    const validationError = this.validateInput(this.state.product_name);
    this.setState({
      product_name_error: validationError,
    });
  };


  handleDiscardImageAndName=()=>{
    this.setState({
      productImage:null,
      product_name:""
    })
  }

  handleproductImageError=()=>{
    this.state.productImage===null ?
      this.setState({
        productImageError:true
      }) 
      :
      this.setState({
        productImageError: false
      }) 
  }
 
  getInitialValues = () => {
      return {
        canbesold: this.state.canbesold,
        canbepurchased: this.state.canbepurchased,
        product_type: this.state.generalinfo.product_type,
        invoice_policy: this.state.generalinfo.invoice_policy,
        unit_of_measure: this.state.generalinfo.unit_of_measure,
        purchased_uom: this.state.generalinfo.purchased_uom,
        product_code: this.state.generalinfo.product_code,
        sales_price: this.state.generalinfo.sales_price,
        customer_tax: this.state.generalinfo.customer_tax?this.state.generalinfo.customer_tax:[],
        product_category: this.state.generalinfo.product_category,
        internal_notes: this.state.generalinfo.internal_notes,
        cost: this.state.generalinfo.cost,
  
        optional_products: this.state.sales.optional_products?this.state.sales.optional_products:[],
        accessory_products: this.state.sales.accessory_products?this.state.sales.accessory_products:[],
        alternative_products: this.state.sales.alternative_products,
        available_in_POS: this.state.sales.available_in_POS,
        to_weigh_with_scale: this.state.sales.to_weigh_with_scale,
        sales_description: this.state.sales.sales_description,
        account_id: this.state.sales.id,
        extra_product_media: this.state.sales.extra_product_media,
  
        responsible: this.state.inventory.responsible,
        barcode: this.state.inventory.barcode,
        weight: this.state.inventory.weight,
        trackingValue: this.state.inventory.tracking,
        volume: this.state.inventory.volume,
        manuf_lead_time: this.state.inventory.manuf_lead_time,
        customer_lead_time: this.state.inventory.customer_lead_time,
        hs_code: this.state.inventory.hs_code,
        description_for_receipts: this.state.inventory.receipts_description,
        description_for_delivery_orders: this.state.inventory.delivery_orders_description,
        description_for_internal_transfers: this.state.inventory.internal_transfer_description,
  
        income_account: this.state.accounting.income_account,
        expense_account: this.state.accounting.expense_account,
        price_difference_account: this.state.accounting.price_difference_account,
        commodity_code: this.state.accounting.commodity_code,
        country_of_origin: this.state.accounting.country_of_origin,
        automatic_email_at_invoice: this.state.accounting.automatic_email_at_invoice,
        product_id: this.state.accounting.product_id,
        accounting_account_id: this.state.accounting.accounting_account_id,
  
        vendor_tax:this.state.purchase.vendor_tax?[this.state.purchase.vendor_tax]:[],
        control_policy: this.state.purchase.control_policy,
        description_for_purchase: this.state.purchase.purchase_description
      };
    
  }

  validationSchema = Yup.object().shape({
    product_type: Yup.string().required("Product Type is required"),
    invoice_policy: Yup.string().required("Invoice Policy is required"),
    unit_of_measure: Yup.string().required("Unit of Measure is required"),
    purchased_uom: Yup.string().required("Purchased Uom is required"),
    product_code: Yup.string().min(3, "Product Code must be min 3 and max 46 characters").max(46, "Product Code must be min 3 and max 46 characters").required("Product Code is required"),
    sales_price: Yup.string().required("Sales Price is required"),
    customer_tax: Yup.array().required("Customer Tax is required"),
    product_category: Yup.string().required("Product Category is required"),
    internal_notes: Yup.string(),
    cost: Yup.number().required("Cost is required"),
    optional_products: Yup.array(),
    accessory_products: Yup.array(),
    alternative_products: Yup.array(),
    available_in_POS: Yup.boolean().required("Available in POS is required"),
    to_weigh_with_scale: Yup.boolean().required("To weigh with scale is required"),
    sales_description: Yup.string(),
    extra_product_media: Yup.array(),

    responsible: Yup.string().required("Responsible is required"),
    barcode: Yup.string(),
    weight: Yup.string().matches(/^\d*\.{1}\d*$/, "Weight must contain only numbers").max(46, "Weight must not exceed 46 characters").required("Weight is required"),
    trackingValue: Yup.string().required("Tracking is required"),
    volume: Yup.string().matches(/^\d*\.{1}\d*$/, "Volume must contain only numbers").max(46, "Volume must not exceed 46 characters").required("Volume is required"),
    manuf_lead_time: Yup.string().matches(/^\d+$/, "Manuf. Lead Time must contain only numbers").max(46, "Manuf. Lead Time must not exceed max 46 characters").required("Manuf. Lead Time is required"),
    customer_lead_time: Yup.string().matches(/^\d+$/, "Customer Lead Time must contain only numbers").max(46, "Customer Lead Time must not exceed 46 characters").required("Customer Lead Time is required"),
    hs_code: Yup.string().matches(/^[a-zA-Z0-9]*$/ ,"HS Code must be alphanumeric").min(3, "HS Code must be min 3 and max 46 characters").max(46, "HS Code must be min 3 and max 46 characters"),
    description_for_receipts: Yup.string(),
    description_for_delivery_orders: Yup.string(),
    description_for_internal_transfers: Yup.string(),

    income_account: Yup.string().required("Income Account is required"),
    expense_account: Yup.string().required("Expense Account is required"),
    price_difference_account: Yup.string().required("Price Difference Account is required"),
    commodity_code: Yup.string().matches(/^[a-zA-Z0-9]*$/,"Commodity Code must be alphanumeric").required("Commodity Code is required").min(3, "Commodity Code must be min 3 and max 64 characters").max(64, "Commodity Code must be min 3 and max 64 characters"),
    country_of_origin: Yup.string().required("Country of Origin is required"),
    automatic_email_at_invoice: Yup.string().required("Automatic Email At Invoice is required"), 
    control_policy: Yup.string().required("Control Policy is required")
  });

  handleTabChange = (value: number) => {
    this.setState({
      selectedTab: value,
    });
  };

  productImageChange = () => {
    this.productImageRef.current?.click()
  }

  productChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    event?.target?.files && this.setState({ ...this.state, productImage: event?.target?.files[0] })
  }

  extraMediaChange = () => {
    this.extraMediaRef.current?.click();
  }

  handleDeleteImage = (index:number) => {
    this.setState((prevState) => {
      const updatedExtraMedia = [...prevState.sales.extra_product_media];
      updatedExtraMedia.splice(index, 1);

      return {
        sales: {
          ...prevState.sales,
          extra_product_media: updatedExtraMedia
        }
      };
    });
  }

  handleAutoCompleteParser = (values: { id:number, title:string, value:string }[]) =>{
    const valueArray :string[] = []
    if(values){
      values.forEach((item) => {
        valueArray.push(item.title)
      })
      return valueArray;
    }
  }

  
  handleSave = (values: any) => {
    const customerTaxTemp = this.handleAutoCompleteParser(values?.customer_tax)
    const optionalProductsTemp = this.handleAutoCompleteParser(values?.optional_products)
    const accessoryProductsTemp = this.handleAutoCompleteParser(values?.accessory_products)
    const alternativeProductsTemp = this.handleAutoCompleteParser(values?.alternative_products)
    const vendorTaxTemp = this.handleAutoCompleteParser(values?.vendor_tax)
    const fileList = values?.extra_product_media;

    const token = this.state.token;
    const formdata = new FormData();
    formdata.append("data[product_name]", this.state.product_name);
    formdata.append("data[can_be_sold]", values?.canbesold);
    formdata.append("data[can_be_purchased]", values?.canbepurchased);
    formdata.append("data[product_image]", this.state.productImage);
    formdata.append("data[general_info_attributes][product_type]", values?.product_type);
    formdata.append("data[general_info_attributes][invoice_policy]", values?.invoice_policy);
    formdata.append("data[general_info_attributes][unit_of_measure]", values?.unit_of_measure);
    formdata.append("data[general_info_attributes][purchased_uom]", values?.purchased_uom);
    formdata.append("data[general_info_attributes][product_code]", values?.product_code);
    formdata.append("data[general_info_attributes][sales_price]", values?.sales_price);
    formdata.append("data[general_info_attributes][customer_tax]", JSON.stringify(customerTaxTemp));
    formdata.append("data[general_info_attributes][cost]", values?.cost);
    formdata.append("data[general_info_attributes][product_category]", values?.product_category);
    formdata.append("data[general_info_attributes][internal_notes]", values?.internal_notes);
    formdata.append("data[sale_attributes][optional_products]", JSON.stringify(optionalProductsTemp));
    formdata.append("data[sale_attributes][accessory_products]", JSON.stringify(accessoryProductsTemp));
    formdata.append("data[sale_attributes][alternative_products][]", JSON.stringify(alternativeProductsTemp));
    formdata.append("data[sale_attributes][available_in_POS]", values?.available_in_POS);
    formdata.append("data[sale_attributes][to_weigh_with_scale]", values?.to_weigh_with_scale);
    formdata.append("data[sale_attributes][sales_description]", values?.sales_description);
    formdata.append("data[sale_attributes][account_id]", '317');

    if (fileList && fileList.length > 0) {
      for (const file of fileList) {
        formdata.append(`data[sale_attributes][extra_product_media][]`, file);
      }
    }
    formdata.append("data[inventory_attributes][responsible]", values?.responsible);
    formdata.append("data[inventory_attributes][weight]", values?.weight);
    formdata.append("data[inventory_attributes][volume]", values?.volume);
    formdata.append("data[inventory_attributes][manuf_lead_time]", values?.manuf_lead_time);
    formdata.append("data[inventory_attributes][customer_lead_time]", values?.customer_lead_time);
    formdata.append("data[inventory_attributes][hs_code]", values?.hs_code);
    formdata.append("data[inventory_attributes][tracking]", values?.trackingValue);
    formdata.append("data[inventory_attributes][receipts_description]", values?.description_for_receipts);
    formdata.append("data[inventory_attributes][delivery_orders_description]", values?.description_for_delivery_orders);
    formdata.append("data[inventory_attributes][internal_transfer_description]", values?.description_for_internal_transfers);
    formdata.append("data[purchase_attributes][vendor_tax]", JSON.stringify(vendorTaxTemp));
    formdata.append("data[purchase_attributes][control_policy]", values?.control_policy);
    formdata.append("data[purchase_attributes][purchase_description]", values?.purchase_description);
    formdata.append("data[accounting_attributes][income_account]", values?.income_account);
    formdata.append("data[accounting_attributes][expense_account]", values?.expense_account);
    formdata.append("data[accounting_attributes][price_difference_account]", values?.price_difference_account);
    formdata.append("data[accounting_attributes][commodity_code]", values?.commodity_code);
    formdata.append("data[accounting_attributes][country_of_origin]", values?.country_of_origin);
    formdata.append("data[accounting_attributes][automatic_email_at_invoice]", values?.automatic_email_at_invoice);
    formdata.append("data[product_category_attributes][account_id]","1");
    const endpoint = this.state.productId?this.state.productId:""
    const method = this.state.productId?"PATCH":"POST"
    this.productGeneralInfoAPICallId = this.apiCall(
      token,
      `bx_block_productquickview/products/` + endpoint,
      method,
      formdata,
    );
  };


  apiCall = (token: token, endPoint: string, method: string, body?: object,  contentType?: string 
  ) => {
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  navigatedToProduct = () =>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Itemavailability');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleVendorsListResponse=(e:React.ChangeEvent<HTMLSelectElement>, rowIndex:number)=>{

    const selectedVendor = e.target.value
    
    const updatedState = this.state.vendorsListTableData.map((item, index)=>{
      if(index === rowIndex){
        item.vendor_name = selectedVendor
      }
      return item
    })
    this.setState({
      vendorsListTableData : updatedState
    })
  }

  navigateToCreation=()=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductCreation");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  setEditingTrue = async() => {
    const editingState = {
      isGeneraltabEditing: true,
      isSalesEditing: true,
      isPurchaseEditing: true,
      isAccountingEditing: true,
      isInventoryEditing: true,
      loading:false
    };
    await setStorageData("editingState", JSON.stringify(editingState));
    
    this.navigateToCreation();
  };
  
  setEditingFalse = async(productId:string) => {
    this.getSingleProductData(productId)
    const editingState = {
      isGeneraltabEditing: false,
      isSalesEditing: false,
      isPurchaseEditing: false,
      isAccountingEditing: false,
      isInventoryEditing: false,
      productId:productId,
    };
    await setStorageData("editingState", JSON.stringify(editingState));
    this.navigateToCreation();
  };
  setAllFormOpen=()=>{
    this.setState({
      isGeneraltabEditing:true,
      isSalesEditing:true,
      isPurchaseEditing:true,
      isInventoryEditing:true,
      isAccountingEditing:true,
    })
  }
  getSingleProductData=async(id:string)=>{
    const token = await getStorageData("TOKEN");
    const endpoint = "bx_block_productquickview/products/" + id
    this.getSingleProductAPI = this.apiCall(
      token,
      endpoint,
      "GET"
    );
  }
  
  // Customizable Area End
}